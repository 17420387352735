.email-template {
  label.enable-email {
    input {
      opacity: 0;
    }
  }
}


.inline-controls > select.form-control,
.inline-controls .ant-picker,
.inline-controls > .ant-select{
  display: inline-block;
  margin:0px 20px !important;
  width: 150px;
}

.inline-controls .ant-picker
{
  display: inline-block;
  margin-left:20px !important;
}

.display-inline{
  display: inline-block;
}
.display-inline > select.form-control
{
  display: inline-block;
  margin:0px 20px !important;
  width: 150px;
}

// .display-inline .ant-picker,
// .display-inline .ant-select
// {
//   display: inline-block;
//   margin-left:20px !important;
// }
  

.ant-select-selection-item{
  font-weight: bold !important;
}

.inline-controls > span{
  margin: 0px 10px;
}


.inline-controls .hours{
  display: inline-block;
  width: 50px !important;
 margin: 0px 10px;
  
}

.display-inline .ant-select {
  display: inline-block;
  margin: 0px 10px !important;
  width: 105px;
}
.display-inline .ant-picker{
  margin: 0px 10px !important;
  width: 100px;

}


.checkbox.position-absolute{
  opacity: 0 ;
}

.width-65
{
  width: 65%;
}