.objectives {
  .input-value {
    box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.25) !important;
    min-height: 3.15rem;
  }

  .add,
  .edit,
  .delete {
    width: 1.4rem;
  }

  input.kpi-desc-input {
    width: 8rem;
  }

  .menu {
    background-color: aliceblue;
    z-index: 1000;

    div {
      &:hover {
        background: burlywood;
      }
    }
  }

  .animated {
    animation-fill-mode: none !important;
  }
}


.objectives .col > ul.objectives-sortable{
  padding-left: 0px;
  list-style-type: none;
}
.objectives .col > ul.objectives-sortable li.objectives-sortable-li, body > li.objectives-sortable-li{
  position: relative;
}

.objectives .col > ul.objectives-sortable li.objectives-sortable-li  > span{
  position: absolute;
  top: 3px;
  height: 20px;
  width: 20px;
  color: white;
  right: 65px;
  z-index: 99;
  font-size: 22px;
}

body > li.objectives-sortable-li > span {
  position: absolute;
  top: 10px !important;
  height: 20px;
  width: 20px;
  color: white;
  right: 65px !important;
  z-index: 99;
  font-size: 22px;
}


.objectives .col > ul li::marker, body > li::marker {
  content: ""!important;
}
