ul.user-assigned-elements{
    width: 100%;
    list-style: none;
    padding: 0 10px;
}
 
ul.user-assigned-elements li:first-child{
    list-style: none;
    padding: 10px;
    background-color:  rgb(17, 41, 69);
    color: #fff;
}


ul.goal-assigned-elements {
    width: 100%;
    list-style: none;
    
}
 
ul.goal-assigned-elements li:first-child{
    list-style: none;
    padding: 10px;
    background-color: rgb(254, 103, 0);
}


ul.strategy-assigned-elements {
    width: 100%;
    list-style: none;
}
 
ul.strategy-assigned-elements li:first-child{
    list-style: none;
    padding: 10px;
    box-shadow: inset 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    color: #23282c;
    background-color: #fff;
}


ul.action-assigned-elements {
    width: 100%;
    list-style: none;
    padding-left: 0px;
}
 
ul.action-assigned-elements li:first-child{
    list-style: none;
    padding: 10px;
    color: #23282c;
    background-color: #fff;
}

.champion-selection{
    padding:20px 0px 20px 200px;
    border-bottom:2px solid lightgray;
}

.has-error {
    border: 1px solid rgba(255, 0, 0, 0.568);
}