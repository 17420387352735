/* NOTE: the order of these styles DO matter */

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #edd5c3;
  color: white;
  border: 1px solid #edd5c3;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #fe6700;
  color: white;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #fe6700;
  color: white;
}

/* Will edit when the second date (end date) in a range of dates
    is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__selected_span:hover {
  background: #dbbaa2;
}

.CalendaryDay__default:hover {
  background: #e3e7ed;
}

.DateRangePickerInput {
  background-color: inherit;
}

.DateInput,
.DateInput_input {
  color: inherit;
  background-color: inherit;
  opacity: 0.9;
}

.DateRangePickerInput_calendarIcon {
  color: inherit;
  opacity: 0.9;
}

.DateRangePickerInput_arrow {
  color: inherit;
  opacity: 0.9;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.9; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
}

.DateRangePicker.DateRangePicker_1 {
  width: 13rem;
}

.DateRangePickerInput__withBorder {
  border: 0;
}

.DateInput_input {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #23282c;
  opacity: inherit;
}

.DateInput_input__disabled {
  font-style: normal;
}

.DateInput_input:focus {
  border-color: #2a66ab;
}

.DateInput_input__small {
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: inherit;
}
