$addBtnWidth: 1.4rem;

.objective {

  &--br {
    border-right: .1em solid white;
  }

  & .expand-collapse {
    width: 8rem;

    &[disabled] {
      cursor: initial;
    }
  }

  & .add {
    width: $addBtnWidth;
  }

  & .add--mr {
    margin-right: 14px;
  }

  /* So the goals dropdown will line up with the other dropdowns */
  & .goals-dropdown {
    margin-right: calc(1rem + #{$addBtnWidth} + #{$addBtnWidth});
  }
}
