.my-client {
  .threshold-input-container {
    max-width: 6rem;
  }

  label.enable-email {
    input {
      opacity: 0;
    }
  }
}
