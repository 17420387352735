// Here you can add other styles
.sidebar {
  background-color: #151f2d;
}

html {
  word-break: normal !important;
}

body,
.bg-body {
  background-color: $background-body;
}

.color-body {
  color: $background-body;
}

.dropdown-badge {
  padding: 0 0.2rem;
}

.font-primary {
  font-family: $font-primary;
}

.select {
  appearance: none;
  border: 0;
  border-radius: 0;
  padding: 0 0.75rem !important;
}

.color .col-sm-3 .sketch-picker {
  margin-left: -4.5rem;
}

.shadow--dark {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25) !important;
}

.shadow--inset {
  box-shadow: inset 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
}

.cursor-pointer {
  cursor: pointer;
}

.tracking-wide {
  letter-spacing: 1px;
}

// Preserve white space
.white-space-pre {
  white-space: pre;
}

.DateInput_input {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;

  &:focus {
    opacity: 1 !important;
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color !important
  }

  &__disabled {
    background-color: inherit !important;
    border: 0 !important;
  }

  &[disabled] {
    cursor: initial;
  }
}

.pd-10{
  padding:10px;
}

.filter-position{
  position: absolute;
left: -107px;
top: 6px;
}

.filter-position-client{
  position: absolute;
  left: -275px;
  top: 3px;
  border: 1px solid #112945;
  border-radius: 3px;
}

.filter-position-client  button.dropdown-toggle{
  width: 150px  !important ;
    height: 30px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}