@import '../scss/variables';

.action {
  .Resources {
    opacity: 0.9;
  }

  .form-control {
    border-color: #a9a9a9;
  }
}
