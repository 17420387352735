// Variable overrides
$background-body: #e3e7ed;

$theme-colors: (
  "primary": #112945,
  "secondary": #fe6700,
  "body": $background-body
);

$font-primary: 'Trajan Pro';

$rag-c: blue;
$rag-r: red;
$rag-a: yellow;
$rag-g: #28a745;
$rag-n: #c2c2c2;
